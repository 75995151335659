import React from "react";
import moment from "moment";

const CoursePurchaseRow = ({ course }) => {
  const renderExpiration = () => {
    if (!course.has_access) {
      return <span className="badge badge-pill bg-secondary">Inactivo</span>;
    }
    let expiration_date = moment(course.expiration_date).utc().endOf("day");
    if (expiration_date.isValid()) {
      let expiration_date_string = expiration_date.format("DD MMM YYYY HH:mm");
      if (expiration_date.isAfter(moment().utc())) {
        return (
          <span className={`badge badge-pill bg-success`}>
            Vigente hasta {expiration_date_string}
          </span>
        );
      }
      return (
        <span className={`badge badge-pill bg-danger`}>
          Expirado el {expiration_date_string}
        </span>
      );
    }
    return <span className="badge badge-pill bg-success">Activo</span>;
  };

  const renderName = () => {
    if (course.course && course.course !== null) {
      return course.course.name;
    }
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>{renderName()}</td>
      <td>{renderExpiration()}</td>
    </tr>
  );
};

export default CoursePurchaseRow;
